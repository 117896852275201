<template>
  <div>
    <b-card
      title="Sıfır Satış Blog"
      no-body
    >
      <b-card-header>
        <b-card-title>Sıfır Satış Blog</b-card-title>
        <b-button
          :to="'/marketing/mobile-app/sales-blog/add'"
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
        >
          <template #cell(title)="data">
            <div v-if="data.item.brand">
              <small class="text-warning">{{ data.item.brand }}</small>
            </div>
            {{ data.item.title }}
          </template>
          <template #cell(created)="data">
            {{ moment(data.item.created).format('DD.MM.YYYY') }}
          </template>
          <template #cell(control)="data">
            <b-dropdown
              :split-to="'/marketing/mobile-app/sales-blog/edit/' + data.item.id"
              text="Görüntüle"
              variant="outline-primary"
              size="sm"
              split
            >
              <b-dropdown-item :to="'/marketing/mobile-app/sales-blog/edit/' + data.item.id">
                Güncelle
              </b-dropdown-item>
              <b-dropdown-item @click="removeData(data.item.id)">
                Sil
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <template v-else>
        <b-alert
          show
          variant="info"
          class="m-2"
        >
          <div class="alert-body text-center">
            Hiç kayıtlı veri bulunmuyor.
          </div>
        </b-alert>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert, BCard, BCardFooter, BDropdown, BDropdownItem, BPagination, BTable, BCardTitle, BCardHeader, BButton,
} from 'bootstrap-vue'

export default {
  name: 'SaleDiscover',
  components: {
    BCardFooter,
    BPagination,
    BDropdownItem,
    BDropdown,
    BAlert,
    BTable,
    BCard,
    BCardTitle,
    BCardHeader,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'title',
          label: 'Başlık',
          thClass: 'align-middle',
        },
        {
          key: 'created',
          label: 'Tarih',
          thClass: 'align-middle',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '220px' },
        },
      ],
      dataQuery: {
        select: [
          'blog_app.id AS id',
          'blog_app.title AS title',
          'com_brand.name AS brand',
          'blog_app.created AS created',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['marketingMobileAppSaleDiscover/getItemList']
    },
    dataCount() {
      return this.$store.getters['marketingMobileAppSaleDiscover/getItemCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('marketingMobileAppSaleDiscover/itemDataList', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('marketingMobileAppSaleDiscover/deleteData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
  },
}
</script>
